
export default {
  name: 'IntersectionObserver',
  props: {
    sentinalName: {
      type: String,
      default: 'sentinal'
    },
    indexToWatch: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isIntersectingElement: false
    }
  },
  watch: {
    isIntersectingElement: function (value) {
      this.$emit('on-intersection-element', value, this.indexToWatch)
    }
  },
  mounted() {
    const sentinal = this.$refs[this.sentinalName]
    const handler = (entries) => {
      if (entries[0].isIntersecting) {
        this.isIntersectingElement = true
      } else {
        //We don't want it to return false if not in viewport
        this.isIntersectingElement = false
      }
    }
    const observer = new window.IntersectionObserver(handler)
    observer.observe(sentinal)
  }
}
