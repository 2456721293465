import { render, staticRenderFns } from "./ServicesSlider.vue?vue&type=template&id=2293a868&scoped=true"
import script from "./ServicesSlider.vue?vue&type=script&lang=js"
export * from "./ServicesSlider.vue?vue&type=script&lang=js"
import style0 from "./ServicesSlider.vue?vue&type=style&index=0&id=2293a868&prod&scoped=true&lang=postcss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2293a868",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Richtext: require('/vercel/path0/components/elements/Richtext.vue').default,Link: require('/vercel/path0/components/elements/Link.vue').default,IntersectionObserver: require('/vercel/path0/components/elements/IntersectionObserver.vue').default,Medias: require('/vercel/path0/components/elements/Medias/index.vue').default,Button: require('/vercel/path0/components/elements/Button.vue').default,Section: require('/vercel/path0/components/elements/Section.vue').default})
