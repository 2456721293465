const elPos = {
  methods: {
    pos (el) {
      const rect = el.getBoundingClientRect();
      return {
        x: rect.x,
        y: rect.y,
        top: rect.top,
        right: rect.right,
        bottom: rect.bottom,
        left: rect.left,
        height: rect.height,
        width: rect.width
      }
    }
  }
}

const isVisible = {
  methods: {
    isVisible (el) {
      const rect = el.getBoundingClientRect();
      return {
        top: rect.top >= 0,
        right: rect.right <= (window.innerWidth || document.documentElement.clientWidth),
        bottom: rect.bottom <= (window.innerHeight || document.documentElement.clientHeight),
        left: rect.left >= 0,
        y: rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight),
        x: rect.left >= 0 && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      }
    }
  }
}

const getCoords = {
  methods: {
    getCoords (el) {
      const rect = el.getBoundingClientRect();
      const body = document.body;
      const docEl = document.documentElement;

      let scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
      let scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;
      let clientTop = docEl.clientTop || body.clientTop || 0;
      let clientLeft = docEl.clientLeft || body.clientLeft || 0;

      return {
        top: Math.round(rect.top + scrollTop - clientTop),
        left: Math.round(rect.left + scrollLeft - clientLeft)
      }
    }
  }
}


export { elPos, isVisible, getCoords };
