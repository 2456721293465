
import { getCoords } from '@/mixins/elPosMixins'
export default {
  name: 'SectionVerticalStickySlider',
  mixins: [getCoords],
  props: ['data'],

  data() {
    return {
      isIntersectingElement: 0,
      currentSection: null,
      distanceFromTop: 0,
      sections: null,
      headerHeightDesktop: 213, // why is it a fixed value?
      test: 0,
    }
  },
  mounted() {
    this.distanceFromTop = this.getCoords(this.$refs.servicesSlider).top;
    const mobileSectionServices = document.getElementById('mobile-services');
    const stickyButton = document.getElementById('sticky-button');
    // TODO: To improve
    if (window.innerWidth <= 768) {
      window.addEventListener('scroll', () => {
        if (this.isInViewport(mobileSectionServices)) {
          stickyButton.classList.add('visible');
          stickyButton.classList.remove('invisible');
        } else {
          stickyButton.classList.add('invisible');
          stickyButton.classList.remove('visible');
        }
      });
    }
  },
  methods: {
    onIntersectionElement(value, indexToWatch) {
      if (value) {
        this.isIntersectingElement = indexToWatch
      }
    },
    skipServices() {
      // scroll to the end of the services
      window.scrollTo({ top: this.distanceFromTop + this.data.services.length * window.innerHeight + window.innerHeight / 2 + this.headerHeightDesktop, behavior: 'smooth' })
    },
    isInViewport(element) {
      const rect = element.getBoundingClientRect();
      // Top <= 0 => element top reaches the very top of the viewport, element is ON the screen
      // Bottom >= window.innerHeight => element bottom reaches the top of the viewport, element is OUT of the screen

      return rect.top <= 0 && rect.bottom >= window.innerHeight;
    },
  }
}
